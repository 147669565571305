<template>
    <b-modal
        :title="$t('storage.update_available')"
        modal-class="modal-update"
        v-model="isModalOpen"
        lazy
        no-close-on-esc
        no-close-on-backdrop
        :ok-title="$t('storage.update_force')"
        :cancel-title="$t('storage.not_now')"
        @ok="forceUpdate"
    >
		<div>
			<a :href="$t('global.link_pwa_updater')" target="_blank">
				{{ $t('storage.open_changes') }}
				<font-awesome-icon :icon="['fal', 'external-link-square-alt']" />
			</a>
		</div>
        {{ $t('storage.infos_maj') }}
    </b-modal>
</template>

<script>
import Common from '@/assets/js/common'

export default {
    name: 'PwaUpdater',
    data: () => ({
        /**
         * Indique l'état de la mise à jour
         * @see @/assets/js.offline.js pour voir les différents états possibles
         */
        updateState: 'CHECKING'
    }),
    computed: {
        isModalOpen() {
            return this.updateState === 'UPDATE_READY'
        }
    },
    mounted() {
        this.waitForUpdate()
    },
    methods: {
        async waitForUpdate() {
			try {
				await Common.waitUntil(
					() => (window.groomyPwa.updateState === 'UPDATE_READY'),
					30000,
					1000
				)
				this.updateState = 'UPDATE_READY'
			} catch {
				this.updateState = 'NO_UPDATE'
			}
        },
        forceUpdate() {
            window.location.reload()
        }
    }
}
</script>
